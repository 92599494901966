<template>
  <div>
    <DataTable
      :loading="loading"
      :headers="headers"
      v-bind="files"
      class="tw-w-1/2"
    >
      <template #item-title="{ item }">
        <a v-if="item.url" :href="item.url" target="_blank">{{ item.name }}</a>
      </template>
      <template #item-created_on="{ item }">
        <span v-if="item.created_on">{{ item.created_on | datetime }}</span>
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import { errorModal } from '@/modalMessages'
import { getLeadFiles } from '@/services/sales'
export default {
  name: 'LeadFilePanel',
  components: { DataTable },
  props: {
    leadId: {
      type: [String, Number]
    }
  },
  data () {
    return {
      loading: false,
      files: {
        count: null,
        next: null,
        previous: null,
        results: []
      }
    }
  },
  computed: {
    headers () {
      return [
        { value: 'title', text: 'Bestand' },
        { value: 'type_name', text: 'Type' },
        { value: 'created_on', text: 'Aangemaakt op' }
      ]
    }
  },
  created () {
    this.loadFiles()
  },
  methods: {
    async loadFiles () {
      try {
        this.loading = true
        const response = await getLeadFiles(this.leadId)
        this.files = response.data
        this.loading = false
        return response
      } catch (error) {
        console.error(error)
        errorModal('Fout bij het laden van bestanden, probeer het opnieuw.')
      }
    }
  }
}
</script>
